.form_item {
  transform: translateY(15px);
  transition: opacity 1s ease, transform 1s ease;
  animation: fadeIn 1s;
}

.counter_card_countainer {
  transition: opacity 1s ease, transform 1s ease;
  animation: leftIn 1s;
  margin: 1rem;
}
.counter_card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #000;
  font-weight: bold;
  font-size: 1.5rem;
  transition: opacity 1s ease, transform 1s ease;
  animation: fadeIn 1s ease-in-out;
  animation-fill-mode: forwards;
}

.no_display {
  opacity: 0;
}

.display {
  opacity: 1;
}

.button_counter {
  border: 1px solid;
}

.button_counter svg {
  font-weight: bold;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes leftIn {
  0% {
    transform: translateX(-200px);
  }
  100% {
    transform: translateX(0);
  }
}
